<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'listPicker'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-arial text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6" @click="closeListPicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl p-5">

          <!-- Heading -->
          <div class="text-xl cursor-pointer" @click="closeListPicker">schließen</div>
          <div class="mt-6 font-faro font-bold text-3xl">Listen</div>

          <div v-if="myLists" class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
            <div v-for="(list, index) in myLists" :key="list">
              <singleSelector :label = list.Bezeichnung
              :border = "index < myLists.length -1 ? true : false"
              :selected = "list[listName]?.ids?.includes(currentQuestionId)"
              @selectItem="selectItem(index, list[listName]?.ids?.includes(currentQuestionId))" />
            </div>
          </div>

        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../mixins/iosVibrations.vue';

// Components
import singleSelector from './singleSelector.vue';

export default {
  name: 'listPicker',
  props: ['currentQuestionId', 'listName'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { singleSelector },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    myLists() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.myLists;
    },
    updateUserDataList() {
      return this.$store.state.updateUserDataList;
    },
    userId() {
      return this.$store.state.auth.userId;
    },
    userDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
  },

  methods: {
    selectItem(index, selected) {
      const id = this.currentQuestionId;
      const { listName } = this;
      const storeLists = this.myLists;
      const storeID = storeLists[index][listName].ids;

      storeLists.forEach((storeList) => {
        if (storeList[listName].ids.includes(id)) {
          const itemIndex = storeList[listName].ids.indexOf(id);
          storeList[listName].ids.splice(itemIndex, 1);
        }
      });

      if (!selected) {
        storeID.push(id);
      }

      this.updateUserDataList({ userDataId: this.userDataId, list: storeLists });
    },

    closeListPicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
