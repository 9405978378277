<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'battleRoundOutro'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto flex flex-col">

        <!-- Content -->
        <div class="w-full flex-grow"></div>
        <div class="w-full h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">

          <!-- Fixed -->
          <div class="h-auto text-center">
            <div v-if="!battleCompleted" class="my-2 font-faro font-bold text-3xl">Battle</div>
            <div v-else class="my-2 font-faro font-bold text-3xl">Battle abgeschlossen</div>
            <div v-if="!battleCompleted">
              <div>Runde {{ currentTraining.roundNumber }} von {{ currentTraining.totalRounds }} abgeschlossen,</div>
              <div>du hast {{ currentTraining.totalCorrect }} {{ currentTraining.totalCorrect === 1 ? 'Frage' : 'Fragen'}} richtig beantwortet.</div>
            </div>
            <div v-else>
              <div>Du hast in {{ currentTraining.totalRounds }} {{ currentTraining.totalRounds === 1 ? 'Runde' : 'Runden'}} ingesamt {{ creatorOrOpponent === 'creator' ? creatorPoints : opponentPoints }} {{ currentTraining.totalCorrect === 1 ? 'Frage' : 'Fragen'}} richtig beantwortet.</div>
            </div>
          </div>

          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

            <!-- Images -->
            <div v-if="currentBattle && battleCompleted && resultsVisible" class="flex flex-row w-full justify-center items-center my-10">
              <div class="w-32 h-full flex flex-col justify-center items-center flex-shrink-0">
                <div class="transition ease-in-out delay-150 relative w-24 h-24 rounded-full bg-white flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden"
                :class="creatorPoints >= opponentPoints ? 'ring-4 ring-green-300' : 'ring-4 ring-red-300'">
                  <div v-if="currentBattle?.creator?.data?.attributes?.avatar?.data" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + currentBattle?.creator?.data?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="mt-2">{{ currentBattle?.creator?.data?.attributes?.nickname }}</div>
                <div class="font-bold">{{ creatorPoints }} Punkte</div>
              </div>
              <div class="font-bold mx-3 text-2xl h-20">VS</div>
              <div class="w-32 h-full flex flex-col justify-center items-center flex-shrink-0">
                <div class="transition ease-in-out delay-150 relative w-24 h-24 rounded-full bg-white flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden"
                :class="opponentPoints >= creatorPoints ? 'ring-4 ring-green-300' : 'ring-4 ring-red-300'">
                  <div v-if="currentBattle?.opponent?.data?.attributes?.avatar?.data" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + currentBattle?.opponent?.data?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="mt-2">{{ currentBattle?.opponent?.data?.attributes?.nickname }}</div>
                <div class="font-bold">{{ opponentPoints }} Punkte</div>
              </div>
            </div>

            <!-- Result -->
            <div v-if="battleCompleted && resultsVisible" class="text-lg text-center w-full px-5 pb-10 font-bold">
              <div v-if="didIWin && !uneven">Super, du hast gewonnen.</div>
              <div v-else-if="!didIWin && !uneven">Du hast leider verloren.</div>
              <div v-else>Unentschieden</div>
            </div>

            <!-- Wait for Player Two to finish -->
            <div v-if="battleCompleted && !resultsVisible && currentBattle && currentBattle[playerTwo]" class="text-lg text-center w-full px-5 py-10">
              Du hast alle Battle-Runden absolviert. Du bekommst eine Benachrichtigung, sobald {{ currentBattle[playerTwo]?.data?.attributes?.nickname }} die letzte Runde beendet hat und das Ergebnis vorliegt.
            </div>

            <div v-if="playerTwoReady && !battleCompleted" class="w-full pt-10">
              <customButton label="Nächste Runde starten" @click="$emit('buttonClicked')" />
              <customButton class="mt-3" label="Zurück zur Übersicht" @click="endBattleWithNotify()" />
            </div>


            <div v-else-if="!battleCompleted && currentBattle && currentBattle[playerTwo]" class="w-full">
              <div class="mt-10 text-center mb-10">{{ currentBattle[playerTwo]?.data?.attributes?.nickname }} hat die Fragen noch nicht beantwortet. Du bekommst eine Benachrichtigung, sobald die nächste Runde startet.</div>
              <customButton class="mt-3" label="Zurück zur Übersicht" @click="endBattle" />
            </div>

            <div v-else class="w-full">
              <customButton class="mt-3" label="Battle beenden" @click="closeBattle" />
            </div>


          </div>

        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import points from '../../../mixins/points.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

// SVG
import profileSVG from '../../../assets/svg/profile.vue';

export default {
  name: 'battleRoundOutro',
  props: ['playerTwoReady'],
  mixins: [slideInOutBottom, iosVibrations, points],
  components: { customButton, profileSVG },

  data() {
    return {
      resultsVisible: false,
    };
  },

  computed: {
    myUserData() {
      return this.$store.state.profile.me?.userData?.data;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    currentBattle() {
      return this.$store.state.training?.currentBattle;
    },
    battleCompleted() {
      return this.currentTraining.roundNumber === this.currentTraining.totalRounds;
    },
    creatorOrOpponent() {
      if (this?.currentBattle?.creator?.data?.id === this.$store.state.profile.me?.userData?.data?.id) {
        return 'creator';
      }
      return 'opponent';
    },
    playerFinished() {
      if (this.creatorOrOpponent === 'creator') {
        return 'creatorFinished';
      }
      return 'opponentFinished';
    },
    playerResultsSeen() {
      if (this.creatorOrOpponent === 'creator') {
        return 'creatorResultsSeen';
      }
      return 'opponentResultsSeen';
    },
    playerTwo() {
      if (this.creatorOrOpponent === 'creator') {
        return 'opponent';
      }
      return 'creator';
    },
    playerTwoFinished() {
      if (this.playerTwo === 'creator') {
        return 'creatorFinished';
      }
      return 'opponentFinished';
    },
    playerTwoResultsSeen() {
      if (this.playerTwo === 'creator') {
        return 'creatorResultsSeen';
      }
      return 'opponentResultsSeen';
    },
    creatorPoints() {
      let totalPoints = 0;
      if (this?.currentBattle?.creatorProgress) {
        const { rounds } = this.currentBattle?.creatorProgress;
        rounds.forEach((round) => {
          totalPoints += round.points;
        });
      }
      return totalPoints;
    },
    opponentPoints() {
      let totalPoints = 0;
      if (this?.currentBattle?.opponentProgress) {
        const { rounds } = this.currentBattle.opponentProgress;
        rounds.forEach((round) => {
          totalPoints += round.points;
        });
      }
      return totalPoints;
    },
    didIWin() {
      let winner = false;
      if (this.creatorOrOpponent === 'creator' && this.creatorPoints > this.opponentPoints) winner = true;
      if (this.creatorOrOpponent === 'opponent' && this.opponentPoints > this.creatorPoints) winner = true;
      return winner;
    },
    uneven() {
      return this.creatorPoints === this.opponentPoints;
    },
    finishBattle() {
      return this.$store.state.finishBattle;
    },
    battleResultsNotification() {
      return this.$store.state.battleResultsNotification;
    },
    queryMe() {
      return this.$store.state.queryMeEx;
    },
    queryMyBattlesEx() {
      return this.$store.state.queryMyBattlesEx;
    },
    deletePendingBattle() {
      return this.$store.state.deletePendingBattle;
    },
    nextRoundNotification() {
      return this.$store.state.nextRoundNotification;
    },
  },

  watch: {
    currentBattle() {
      if (this?.currentBattle[this.playerTwoFinished] || this?.currentTraining?.finished) {
        this.resultsVisible = true;
      }
    },
  },

  methods: {

    endBattleWithNotify() {
      this.notifyPlayerForNextRound();
      this.endBattle();
    },

    notifyPlayerForNextRound() {
      this.nextRoundNotification({ playerTwo: this?.currentBattle[this.playerTwo]?.data?.id, courseId: this.currentCourseId })
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            console.log(result);
          }
        });
    },

    endBattle() {
      this.iosVibrate(0);
      this.queryMyBattlesEx().then(() => {
        this.$store.commit('showSecondOverlay', null);
        this.$router.push({ name: 'training' });
      });
    },


    closeBattle() {
      this.iosVibrate(0);
      // if player two done
      if (this.currentBattle[this.playerTwoFinished]) {
        // save results seen
        this.finishBattle({ id: this.currentTraining.battleId, [this.playerResultsSeen]: true })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              console.log(result);
            }
          });
        // send final notification to playerTwo "auswertung verfügbar etc.."
        this.battleResultsNotification({ playerTwo: this?.currentBattle[this.playerTwo]?.data?.id, courseId: this.currentCourseId })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              console.log(result);
            }
          });

        // save points if winner
        if (this.didIWin || this.uneven) {
          console.log('saving points');
          this.savePoints({ pointCategory: 'battlePoints' });
        }
      } else
      // if playerTwo results seen
      if (this.currentBattle[this.playerTwoResultsSeen]) {
        // delete Battle
        console.log('delete battle now');
        this.deletePendingBattle({ id: this.currentTraining.battleId })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              this.queryMyBattlesEx();
              this.queryMe();
              console.log(result);
            }
          });
      } else {
        // save finished to battle
        this.finishBattle({ id: this.currentTraining.battleId, [this.playerFinished]: true })
          .then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              this.queryMyBattlesEx();
              this.queryMe();
              console.log(result);
            }
          });
      }
      this.queryMyBattlesEx().then(() => {
        this.$store.commit('showSecondOverlay', null);
        this.$router.push({ name: 'training' });
      });
    },
  },
};
</script>
