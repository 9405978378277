<template>
  <div class="w-full p-3 flex flex-row justify-between items-center text-new-black font-arial cursor-pointer" :class="{'border-b border-new-yellow' : border, 'bg-new-black' : selected}" @click="$emit('selectItem')">
    <div class="flex-grow pr-2 flex flex-row justify-between items-center">
      <div class="text-lg" :class="{'text-white' : selected}">{{label}}</div>
      <checkSVG v-if="selected" />
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../mixins/iosVibrations.vue';

// SVG
import checkSVG from '../assets/svg/check.vue';

export default {
  name: 'singleSelector',
  props: ['label', 'border', 'selected'],
  mixins: [iosVibrations],
  components: { checkSVG },
};
</script>
