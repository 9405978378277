<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'battleRoundIntro'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto flex flex-col">

        <!-- Content -->
        <div class="w-full flex-grow" @click="hidePicker"></div>
        <div class="w-full h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">

          <!-- Fixed -->
          <div class="h-auto text-center">
            <div class="my-2 font-faro font-bold text-3xl">Battle</div>
            <div>Runde {{ currentTraining.roundNumber }} von {{ currentTraining.totalRounds }}</div>
          </div>

          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">

            <!-- Images -->
            <div class="flex flex-row w-full justify-center items-center my-10">
              <div class="w-32 h-full flex flex-col justify-center items-center flex-shrink-0">
                <div class="relative w-24 h-24 rounded-full bg-white flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden">
                  <div v-if="currentBattle?.creator?.data?.attributes?.avatar?.data" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + currentBattle?.creator?.data?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="mt-2">{{ currentBattle?.creator?.data?.attributes?.nickname }}</div>
              </div>
              <div class="font-bold">VS</div>
              <div class="w-32 h-full flex flex-col justify-center items-center flex-shrink-0">
                <div class="relative w-24 h-24 rounded-full bg-white flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden">
                  <div v-if="currentBattle?.opponent?.data?.attributes?.avatar?.data" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + currentBattle?.opponent?.data?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="mt-2">{{ currentBattle?.opponent?.data?.attributes?.nickname }}</div>
              </div>
            </div>

            <div class="w-full">
              <customButton :label="`Runde ${currentTraining.roundNumber} starten`" @click="hidePicker" />
            </div>


          </div>

        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

// SVG
import profileSVG from '../../../assets/svg/profile.vue';

export default {
  name: 'battleRoundIntro',
  mixins: [slideInOutBottom, iosVibrations],
  components: { profileSVG, customButton },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    currentBattle() {
      return this.$store.state.training?.currentBattle;
    },
  },

  methods: {
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
