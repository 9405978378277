<template>
  <div>
    <!-- Components -->
    <darkBackground />
    <darkBackground2 />
    <infoOverlay :text="currentTraining?.type === 'battle' ? 'Möchtest du die Battle-Runde beenden? Nicht gespielte Fragen werden für diese Runde als Falsch ausgewertet.' : 'Möchtest du das Training wirklich beenden?'" :button="currentTraining?.type === 'battle' ? 'Battle Runde beenden' : 'Training beenden'" @buttonClicked="confirmCancelTraining" index=1 />
    <infoOverlay text="Bist du sicher, dass du alle Fragen beantwortet hast?" button="Zur Auswertung" index=2 @buttonClicked="finishTraining" />
    <infoOverlay text="Deine Zeit ist abgelaufen" button="Zum Ergebnis" index=3 @buttonClicked="timeIsUp" denyClosing=true />
    <infoOverlay text="Du hast noch nicht alle Antworten ausgewählt." index=4 />
    <queryCurrentBattle v-if="currentTraining?.type === 'battle'" />
    <updateBattleRoundMutation v-if="currentTraining?.type === 'battle'" />
    <nextRoundNotificationMutation v-if="currentTraining?.type === 'battle'" />
    <battleResultsNotificationMutation v-if="currentTraining?.type === 'battle'" />
    <finishBattleMutation v-if="currentTraining?.type === 'battle'" />
    <battleRoundIntro v-if="currentTraining?.type === 'battle'" />
    <battleRoundOutro v-if="currentTraining?.type === 'battle'" :playerTwoReady="playerTwoReady" @buttonClicked="nextRound" />
    <overview :currentQuestionId=currentQuestionId :reviewMode="reviewMode" @changeQuestion="changeQuestion" @buttonClicked="finishTraining" />
    <listPicker :currentQuestionId=currentQuestionId listName="mcFragen"/>
    <results resultText="Fragen richtig beantwortet" :max="questions?.length" saveType="Multiple Choice"/>
    <!-- Components -->


    <!-- Nav Bar Overlay-->
    <div class="w-full md:w-24 md:h-screen bg-new-yellow md:bg-transparent fixed flex flex-row md:flex-col justify-between md:justify-between items-center z-10 top-0 md:left-0 p-5 font-arial text-new-black border-b-2 md:border-none  border-new-yellow" :class="{'pt-safe' : parseInt(sat) !== 0, 'transition duration-300 ease-in-out border-hi-lighgrey' : scrollDown}">
      <div class="relative flex flex-row justify-center items-center w-full md:order-last">
        <div class="z-10 cursor-pointer" @click="cancelTraining" >
          <backSVG color='red' class="md:hidden flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
          <backBoldSVG color='red' class="hidden md:block flex-shrink-0 md:mb-5 md:h-10 md:w-10 md:p-2" />
        </div>
        <div v-if="timer" class="md:hidden flex flex-row justify-center items-center w-full text-lg">{{formatTime(remainingTime)}}</div>
        <div v-else class="md:hidden transition duration-300 ease-in-out w-full text-center text-lg opacity-0" :class="{'transition duration-300 ease-in-out opacity-100' : scrollDown}">Frage {{currentQuestion + 1}} von {{questions?.length}}</div>
      </div>
      <starSVG :full="includedInList ? true : false" class=" flex-shrink-0 mr-5 md:mr-0 md:p-2 md:w-12 md:h-12" @click="openListPicker" />
      <gridSVG @click="openOverview" class="flex-shrink-0 md:order-first md:p-2 md:right-0 md:w-12 md:h-12 md:mt-5 cursor-pointer"/>
    </div>
    <!-- Nav Bar Overlay-->


    <!-- Content -->
    <div class="w-full min-h-screen md:max-h-screen md:overflow-hidden p-5 md:p-0 mt-5 md:mt-0 md:pl-24 bg-new-yellow font-arial text-new-black select-none mb-36 md:mb-0 md:flex md:flex-row" :class="{'pt-safe' : parseInt(sat) !== 0}">

      <!-- Desktop Left Side -->
      <div class="md:w-1/2 md:px-10 py-5 md:flex md:flex-col md:h-screen">

        <!-- Heading -->
        <div v-if="currentTraining.type === 'battle'" class="mt-6 font-faro font-bold text-3xl">Battle</div>
        <div v-else class="mt-6 font-faro font-bold text-3xl">Multiple Choice</div>
        <div v-if="timer" class="hidden md:block text-xl">Verbleibende Zeit {{formatTime(remainingTime)}}</div>
        <div class="text-xl">Frage {{currentQuestion + 1}} von {{questions?.length}}</div>

        <!-- Tags -->
          <div class="flex flex-row flex-wrap text-xs justify-start items-center mb-3 mt-3">
            <div v-if="questions[currentQuestion]?.attributes?.pruefungen" class="flex flex-row flex-wrap">
              <div class="bg-new-black text-white p-0.5 px-1.5 rounded-md mr-1 mt-1" v-for="pruefung in questions[currentQuestion]?.attributes?.pruefungen?.data" :key="pruefung">{{pruefung?.Index}}</div>
            </div>
            <div v-if="questions[currentQuestion]?.attributes?.tags?.data?.length > 0" class="flex flex-row flex-wrap">
              <div class="bg-new-black text-white p-0.5 px-1.5 rounded-md mr-1 mt-1" v-for="tag in questions[currentQuestion]?.attributes?.tags?.data" :key="tag">{{tag?.attributes?.Bezeichnung}}</div>
            </div>
          </div>

        <!-- Scrollable -->
        <div class="md:flex-grow md:overflow-y-scroll scrollbar" >

          <!-- Questions -->
          <div class="font-bold">{{questions[currentQuestion].attributes.Index}}</div>
          <tipTap :content="questions[currentQuestion]?.attributes?.Frage" />

          <!-- MC Answers -->
          <div v-if="shouldBeSelectedAnswers === 1" class="mt-5 mb-1 text-xs font-bold">Wähle {{shouldBeSelectedAnswers}} Antwort</div>
          <div v-else class="mt-5 mb-1 text-xs font-bold">Wähle {{shouldBeSelectedAnswers}} Antworten</div>
          <div v-if="questions[currentQuestion]?.attributes?.Antwort" class="w-full bg-white rounded-xl shadow-new border border-hi-lighgrey overflow-hidden">
            <div v-for="(antwort, index) in questions[currentQuestion]?.attributes?.Antwort" :key="antwort">
              <questionSelector
                :label=antwort?.Antwort
                :questionIndex=this?.currentQuestion
                :questionID=questions[currentQuestion]?.id
                :answerID=antwort?.id
                :maxAnswers=shouldBeSelectedAnswers
                :correctAnswersIds=currentProgress?.correctAnswersIds
                :border="index < questions[currentQuestion]?.attributes?.Antwort?.length -1 ? true : false" />
            </div>
          </div>
        </div>

      </div>


      <!-- Desktop Right Side -->
      <div class="md:w-1/2 md:bg-hi-white md:px-10 md:pt-28 md:h-screen">

        <!-- Comment -->
        <div v-if="questions[currentQuestion]?.attributes?.Kommentar" class="md:h-full ">
          <div v-if="showAnswers || reviewMode" class="md:h-full md:overflow-y-auto md:pb-28">
            <div id="comment" class="mt-5 mb-1 text-xs font-bold">Kommentar zur Antwort</div>
              <tipTap :content="questions[currentQuestion]?.attributes?.Kommentar" />
          </div>
        </div>

      </div>

    </div>
    <!-- Content -->

    <!-- Buttons -->
    <div class="fixed bottom-0 w-full md:pl-24 md:flex md:flex-row pointer-events-none">
      <div class="hidden md:block md:w-1/2 pointer-events-none"></div>
      <div class="pb-5 min-h-28 w-full md:w-1/2 p-5 md:px-10 bg-white shadow-top md:shadow-none md:bg-transparent pointer-events-auto">
      <div v-if="!reviewMode">
        <customButton v-if="!showAnswers && currentlySelectedAnswers === shouldBeSelectedAnswers && !examMode" label='Bestätigen' :inactive=false :loading=isChecking @click="check"/>
        <customButton v-else-if="showAnswers && questions.length !== currentQuestion + 1 && !examMode" label='Nächste Frage' :inactive=false @click="next"/>
        <customButton v-else-if="showAnswers && questions.length === currentQuestion + 1 && !examMode" label='Zur Auswertung' :inactive=false @click="next"/>
      </div>
      <div v-if="!reviewMode" class="flex flex-row justify-center items-center">
        <customButton v-if="examMode && currentQuestion !== 0" label='Zurück' :inactive=false @click="previous" class="min-w-1/2 mr-1"/>
        <customButton v-if="examMode && questions.length !== currentQuestion + 1" label='Weiter' @click="next" :inactive=false class="min-w-1/2" :class="{'ml-1' : currentQuestion !== 0}"/>
        <customButton v-if="examMode && questions.length === currentQuestion + 1" label='Auswerten' @click="checkExam" :inactive=false class="min-w-1/2 ml-1"/>
      </div>
      <div v-if="reviewMode" class="flex flex-row justify-center items-center">
        <customButton v-if="currentQuestion !== 0" label='Zurück' @click="previous" :inactive=false class="min-w-1/2 mr-1"/>
        <customButton v-if="questions.length >= currentQuestion + 1" label='Weiter' @click="next" :inactive=false class="min-w-1/2" :class="{'ml-1' : currentQuestion !== 0}"/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

// SVG
import gridSVG from '../../../assets/svg/grid.vue';
import backSVG from '../../../assets/svg/back.vue';
import backBoldSVG from '../../../assets/svg/backBold.vue';
import starSVG from '../../../assets/svg/star.vue';

// Mixins
import detectNotch from '../../../mixins/detectNotch.vue';
import scrollTo from '../../../mixins/scrollTo.vue';
import fadeInOut from '../../../mixins/animations/fadeInOut.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import battleFunctions from '../../../mixins/battleFunctions.vue';
import points from '../../../mixins/points.vue';

// Components
import battleRoundIntro from '../components/battleRoundIntro.vue';
import battleRoundOutro from '../components/battleRoundOutro.vue';
import questionSelector from '../components/questionSelector.vue';
import customButton from '../../../symbols/customButton.vue';
import darkBackground from '../../../symbols/darkBackground.vue';
import darkBackground2 from '../../../symbols/darkBackground2.vue';
import infoOverlay from '../../../symbols/infoOverlay.vue';
import overview from '../components/overview.vue';
import listPicker from '../../../symbols/listPicker.vue';
import results from '../components/results.vue';
import tipTap from '../../../symbols/tipTap.vue';

// Strapi
import queryCurrentBattle from '../strapi/queries/queryCurrentBattle.vue';
import updateBattleRoundMutation from '../strapi/mutations/updateBattleRoundMutation.vue';
import nextRoundNotificationMutation from '../strapi/mutations/nextRoundNotificationMutation.vue';
import battleResultsNotificationMutation from '../strapi/mutations/battleResultsNotificationMutation.vue';
import finishBattleMutation from '../strapi/mutations/finishBattleMutation.vue';


export default {
  name: 'questions',
  mixins: [detectNotch, fadeInOut, iosVibrations, scrollTo, battleFunctions, points],
  components: {
    questionSelector,
    customButton,
    infoOverlay,
    darkBackground,
    darkBackground2,
    tipTap,
    gridSVG,
    backSVG,
    overview,
    listPicker,
    results,
    starSVG,
    backBoldSVG,
    battleRoundIntro,
    queryCurrentBattle,
    battleRoundOutro,
    updateBattleRoundMutation,
    nextRoundNotificationMutation,
    finishBattleMutation,
    battleResultsNotificationMutation,
  },

  data() {
    return {
      currentQuestion: 0,
      endTime: null,
      remainingTime: 0,
      break: false,
      isChecking: false,
    };
  },

  computed: {
    // GETTERS
    currentProgress() {
      return this.$store.getters.currentProgress(this?.currentQuestionId);
    },
    currentlySelectedAnswers() {
      return this.$store.getters.currentlySelectedAnswers(this?.currentQuestionId);
    },
    shouldBeSelectedAnswers() {
      return this.questions[this.currentQuestion]?.attributes?.sumCorrect;
    },
    // STRAPI EXECUTER
    updateUserDataWrongPlayedQuestions() {
      return this.$store.state?.updateUserDataWrongPlayedQuestions;
    },
    updateUserDataRecentPlayedQuestions() {
      return this.$store.state?.updateUserDataRecentPlayedQuestions;
    },
    validateAnswers() {
      return this.$store?.state?.validateAnswers;
    },
    // DATA
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    totalProgres() {
      return this?.currentTraining?.progress;
    },
    questions() {
      return this.$store.state.training?.currentTraining?.questionData;
    },
    currentQuestionId() {
      return this?.questions[this?.currentQuestion]?.id;
    },
    scrollDown() {
      return this.$store.state?.layout?.scrollDown;
    },
    wrongPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.wrongPlayedQuestions;
    },
    recentPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.recentPlayedQuestions;
    },
    totalPlayed() {
      return this.$store.state.profile.me?.userData?.data?.attributes?.totalPlayedQuestions;
    },
    showAnswers() {
      if (this.currentProgress?.result !== null || (this.reviewMode && this.currentTraining?.type === 'battle')) {
        return true;
      }
      return false;
    },
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    timer() {
      return this.$store.state.training?.settings?.time;
    },
    minutes() {
      return this.$store.state.training?.settings?.minutes;
    },
    examMode() {
      return this.$store.state.training?.settings?.exam;
    },
    reviewMode() {
      return this.$store.state.training?.settings?.review;
    },
    includedInList() {
      const myList = this.$store.state?.profile?.me?.userData?.data?.attributes?.myLists;
      let included = false;
      myList.forEach((list) => {
        if (list.mcFragen.ids.includes(this?.currentQuestionId)) {
          included = true;
        }
      });
      return included;
    },
  },

  beforeMount() {
    this.$store.commit('showSecondOverlay', null);
    this.$store.commit('showOverlay', null);
  },

  beforeUnmount() {
    this.break = true;
  },

  mounted() {
    this.scrollToTop();

    if (this.currentTraining?.type === 'battle') {
      this.$nextTick(() => {
        if (this.currentTraining.finished) {
          this.$store.commit('showSecondOverlay', 'battleRoundOutro');
        } else {
          this.$store.commit('showSecondOverlay', 'battleRoundIntro');
        }
      });
    }

    if (this.timer && !this.reviewMode) {
      const now = new Date();
      if (this.currentTraining?.remainingTime) {
        const availableTime = this.currentTraining.remainingTime;
        now.setSeconds(now.getSeconds() + availableTime);
        this.endTime = now;
        this.remainingTime = availableTime;
        this.startCountDown();
      } else {
        const availableTime = this.minutes * 60 * this.questions.length;
        now.setSeconds(now.getSeconds() + availableTime);
        this.endTime = now;
        this.remainingTime = availableTime;
        this.startCountDown();
      }
    }
  },

  methods: {

    nextRound() {
      this.currentQuestion = 0;
      this.loadNextRound();
    },

    finishTraining() {
      this.questions.forEach((question) => {
        this.checkQuestions(question.id);
      });
      setTimeout(() => {
        this.break = true;
        this.$store.commit('finishTraining');
        this.$store.commit('showSecondOverlay', 'results');
      }, 100);
    },

    timeIsUp() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      this.$store.commit('finishTraining');
      this.iosVibrate(1);
      this.scrollToTop();
      this.$store.commit('showSecondOverlay', 'results');
    },

    changeQuestion(index) {
      this.currentQuestion = index;
      this.$store.commit('showOverlay', null);
    },

    openOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'overview');
    },

    openListPicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'listPicker');
    },

    startCountDown() {
      if (this.remainingTime > 0 && !this.break) {
        const elapsed = new Date() / 1000;
        const totalSec = parseInt(this.endTime / 1000 - elapsed, 10);
        this.remainingTime = totalSec;
        this.timeout = setTimeout(this.startCountDown, 1000);
      } else if (!this.break) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 3 });
      }
    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds - (minutes * 60);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (remainingSeconds < 10) {
        remainingSeconds = `0${remainingSeconds}`;
      }
      const timer = `${minutes}:${remainingSeconds}`;
      return timer;
    },

    cancelTraining() {
      this.iosVibrate(1);
      this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
    },

    confirmCancelTraining() {
      this.$store.commit('toggleInfoOverlay', { value: false, level: 1 });
      if (this.currentTraining.type !== 'battle') {
        this.$store.dispatch('saveTraining', { type: 'Multiple Choice', remainingTime: this.timer ? this.remainingTime : null });
        this.$router.push({ name: 'training' });
      }
      if (this.currentTraining.type === 'battle' && !this.reviewMode) {
        this.endBattleRound();
      } else if (this.currentTraining.type === 'battle' && this.reviewMode) {
        this.$store.commit('addToggle', {
          storeID: 'review', storeContainer: 'training', storeWrapper: 'settings', value: false,
        });
        this.$router.push({ name: 'training' });
      }
    },

    check() {
      if (this.currentlySelectedAnswers === this.shouldBeSelectedAnswers) {
        this.iosVibrate(1);
        this.checkQuestion(this.currentQuestionId);
        if (!this.examMode) {
          // scroll to answer
          /* this.$nextTick(() => {
            setTimeout(() => {
              const el = document.getElementById('comment');
              if (el) {
                const y = el.getBoundingClientRect().top + window.scrollY - 80;
                this.scrollToPosition(y);
              }
            }, 350);
          }); */
        }
      }
    },

    checkQuestion(questionId) {
      this.isChecking = true;
      this.validateAnswers({ questionId }).then((result) => {
        const correctAnswers = result.data.validateAnswers.correctIds;
        const selectedAnswers = this.currentProgress.selectedAnswersIds;
        if (correctAnswers.sort().toString() === selectedAnswers.sort().toString()) {
          this.$store.commit('setQuestionResult', { result: 'correct', questionID: questionId, correctAnswersIds: correctAnswers });
          this.$store.commit('totalCorrect');
          this.updateWrongPlayedQuestions(this.currentQuestionId, true);
          this.updateRecentPlayedQuestions(this.currentQuestionId);
          this.savePoints({ pointCategory: 'mcTrainingPoints' });
          this.isChecking = false;
        } else {
          this.$store.commit('setQuestionResult', { result: 'wrong', questionID: questionId, correctAnswersIds: correctAnswers });
          this.updateWrongPlayedQuestions(this.currentQuestionId, false);
          this.updateRecentPlayedQuestions(this.currentQuestionId);
          this.isChecking = false;
        }
      });
    },

    checkQuestions(questionId) {
      this.isChecking = true;
      this.validateAnswers({ questionId }).then((result) => {
        const correctAnswers = result.data.validateAnswers.correctIds;
        const progressIndex = this.totalProgres.findIndex((progress) => progress.questionId === questionId);
        const selectedAnswers = this.totalProgres[progressIndex].selectedAnswersIds;
        if (correctAnswers.sort().toString() === selectedAnswers.sort().toString()) {
          this.$store.commit('setQuestionResult', { result: 'correct', questionID: questionId, correctAnswersIds: correctAnswers });
          this.$store.commit('totalCorrect');
          this.updateWrongPlayedQuestions(this.currentQuestionId, true);
          this.updateRecentPlayedQuestions(this.currentQuestionId);
          this.savePoints({ pointCategory: 'mcTrainingPoints' });
          this.isChecking = false;
        } else {
          this.$store.commit('setQuestionResult', { result: 'wrong', questionID: questionId, correctAnswersIds: correctAnswers });
          this.updateWrongPlayedQuestions(this.currentQuestionId, false);
          this.updateRecentPlayedQuestions(this.currentQuestionId);
          this.isChecking = false;
        }
      });
    },

    updateWrongPlayedQuestions(id, correct) {
      const wrongPlayedArray = this.wrongPlayed;
      const storeID = 'ids';
      if (wrongPlayedArray[storeID].includes(id)) {
        if (correct) {
          const itemIndex = wrongPlayedArray[storeID].indexOf(id);
          wrongPlayedArray[storeID].splice(itemIndex, 1);
          this.updateUserDataWrongPlayedQuestions({ id: this.userDataId, wrongPlayedQuestions: wrongPlayedArray });
        }
      } else if (!correct) {
        wrongPlayedArray[storeID].push(id);
        this.updateUserDataWrongPlayedQuestions({ id: this.userDataId, wrongPlayedQuestions: wrongPlayedArray });
      }
    },

    updateRecentPlayedQuestions(id) {
      const recentPlayedArray = this.recentPlayed;
      const totalPlayedArray = this.totalPlayed;
      if (!totalPlayedArray.ids.includes(id)) {
        totalPlayedArray.ids.push(id);
      }
      if (!recentPlayedArray.ids.includes(id)) {
        if (recentPlayedArray.ids.length >= process.env.VUE_APP_RECENT_PLAYED_QUANTITY) {
          recentPlayedArray.ids.splice(1, 1);
        }
      } else {
        const index = recentPlayedArray.ids.indexOf(id);
        recentPlayedArray.ids.splice(index, 1);
      }
      recentPlayedArray.ids.push(id);
      this.updateUserDataRecentPlayedQuestions({ id: this.userDataId, recentPlayedQuestions: recentPlayedArray, totalPlayedQuestions: totalPlayedArray });
    },

    checkExam() {
      this.$store.commit('toggleInfoOverlay', { value: true, index: 2 });
    },

    next() {
      if (this.examMode && this.currentlySelectedAnswers < this.shouldBeSelectedAnswers && this.currentlySelectedAnswers !== 0 && !this.reviewMode) {
        this.$store.commit('toggleInfoOverlay', { value: true, index: 4 });
      } else {
        if (this.currentQuestion + 1 === this.questions.length) {
          this.iosVibrate(0);
          if (this.currentTraining.type === 'battle') {
            this.endBattleRound();
          } else {
            this.$store.commit('showSecondOverlay', 'results');
            this.$store.commit('finishTraining');
          }
        } else {
          this.currentQuestion += 1;
        }
        this.scrollToTop();
        this.iosVibrate(1);
      }
    },

    previous() {
      if (this.currentQuestion !== 0) {
        this.currentQuestion -= 1;
        this.scrollToTop();
        this.iosVibrate(1);
      }
    },
  },
};
</script>

<style>
ol {
    @apply list-decimal list-outside pl-6
}
li {
    @apply mt-2
}
</style>
